@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500|Arvo:300,400,500,600,700);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html, body, #dst-checker {
    height: 100%;
}

.red-box {
    width: 50px;
    height: 50px;
    background: blue;
}

.text-center {
    text-align: center;
}

.centred {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 16px;
    z-index: 1;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.flex {
    display: flex;
}

.flex-grow {
    flex-grow: 1;
}

table.report-container {
    width:100%;
    border-spacing: 0;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
}

main {
    padding-bottom:90px;
}

footer {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
}

@media print {
    @page {
        size: A4 portrait;
        margin: 0;
    }

    .no-print {
        display: none!important;
    }

    .country {
        flex-basis: 33.3333%!important;
        max-width: 33.3333%!important;
        page-break-inside: avoid;
    }

    .print-full-width {
        width: 100%!important;
        padding: 0!important;
        margin: 0!important;
    }

    table.report-container {
        width:100%;
        border-spacing: 0;
        page-break-after:always;
    }
    thead.report-header {
        display:table-header-group;
    }
    tfoot.report-footer {
        display:table-footer-group;
    }

    table.report-container .item {
        page-break-inside: avoid;
    }

    footer {
        position: fixed;
    }

    .print-page-break {
        //page-break-after:always;
    }

    .summary, .country {
        //height: 100%;
        //page-break-after:always;
    }
}

