@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500|Arvo:300,400,500,600,700');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

html, body, #dst-checker {
    height: 100%;
}

.red-box {
    width: 50px;
    height: 50px;
    background: blue;
}

.text-center {
    text-align: center;
}

.centred {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 16px;
    z-index: 1;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.flex {
    display: flex;
}

.flex-grow {
    flex-grow: 1;
}
