table.report-container {
    width:100%;
    border-spacing: 0;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
}

main {
    padding-bottom:90px;
}

footer {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
}

@media print {
    @page {
        size: A4 portrait;
        margin: 0;
    }

    .no-print {
        display: none!important;
    }

    .country {
        flex-basis: 33.3333%!important;
        max-width: 33.3333%!important;
        page-break-inside: avoid;
    }

    .print-full-width {
        width: 100%!important;
        padding: 0!important;
        margin: 0!important;
    }

    table.report-container {
        width:100%;
        border-spacing: 0;
        page-break-after:always;
    }
    thead.report-header {
        display:table-header-group;
    }
    tfoot.report-footer {
        display:table-footer-group;
    }

    table.report-container .item {
        page-break-inside: avoid;
    }

    footer {
        position: fixed;
    }

    .print-page-break {
        //page-break-after:always;
    }

    .summary, .country {
        //height: 100%;
        //page-break-after:always;
    }
}
